import React, { useEffect, useState } from "react";
import {
  Box,
  ChakraProvider,
  Container,
  Flex,
  Grid,
  GridItem,
  Icon,
} from "@chakra-ui/react";
import Header from "./Components/mainheader";
import FooterCustom from "components/footer/FooterCustom";
import { SubHeader } from "./Components/subheader";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PageNotFound from "components/pages/PageNotFound";
import mainRoutes from "mainroute";
import { filterRoutesByPermissions } from "helper/filterRoutes";
import { useGameStore } from "store/store";
import { Transition } from "@headlessui/react";
import Chatbot from "views/chatbot/components/chatbot";
import { RiRobot2Line } from "react-icons/ri";
import { checkResourcePermission } from "helper/checkResorcePermission";
import { ProtectedRoute } from "components/protectedroutes/protectedroutes";
import { checkChatbot } from "helper/checkChatbot";
import { usePusher } from "context/pusher/PusherContext";
import { toast } from "react-toastify";
import axiosClient from "config/apiClient";

const getRoutes = (routes) => {
  return routes.map((prop, key) => {
    if (prop.children) {
      return (
        <Route path={prop.path} key={key}>
          <Route
            index
            element={
              prop.protected ? (
                <ProtectedRoute>{<Navigate to={prop.children[0].path} replace />}</ProtectedRoute>
              ) : (
                prop.component
              )
            }
            // element={<Navigate to={prop.children[0].path} replace />}
          />
          {prop.children.map((child, childKey) => (
            <Route
              path={child.path}
              // element={child.component}
              element={
                prop.protected ? (
                  <ProtectedRoute>{child.component}</ProtectedRoute>
                ) : (
                  child.component
                )
              }
              key={`${key}-${childKey}`}
            />
          ))}
        </Route>
      );
    } else {
      return <Route path={prop.path}
      element={
        prop.protected ? (
          <ProtectedRoute>{prop.component}</ProtectedRoute>
        ) : (
          prop.component
        )
      }
      key={key} />;
    }
  });
};

export default function MainLayout() {
  const { Game2reset, setauthData, Game3reset, Game1reset , PermissionsList , authData } =
  useGameStore();
  let [isOpen, setIsOpen] = useState(false);
  const {pathname} = useLocation();
  const pusher = usePusher();
  const navigate = useNavigate();

  function clearData() {
    localStorage.removeItem(`user_${authData.username}_price`);
    pusher.disconnect()
    setauthData(null);
    Game1reset();
    Game2reset();
    Game3reset();
    navigate("/auth/login");
  }

  const redirect = async () => {
    try {
      axiosClient.post('/tenant/logout/',{
        refresh: authData.refresh
      }) 
      clearData();
    } catch (error) {
      clearData();
    }
  };

  useEffect(() => {
    function childEventCallback(data) {
      toast.error(
        <>
          <div className="font-Helvetica text-lg font-bold tracking-wide">
            {data?.title}
          </div>
          <div className="font-Helvetica text-base tracking-wide">
            {data?.message}
          </div>
        </>,
        {
          position: "top-center",
          autoClose: 300000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }

    function logoutEventCallback(data) {
      toast.error(
        <>
          <div className="font-Helvetica text-lg font-bold tracking-wide">
            {data?.title}
          </div>
          <div className="font-Helvetica text-base tracking-wide">
            {data?.message}
          </div>
        </>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          onClose: redirect,
          theme: "colored",
        }
      );
    }
    
    let subscriber = `team_${authData?.team_id}`

    if (subscriber) {
      const channel = pusher.subscribe(subscriber);
      channel.bind("my-event", childEventCallback);
      channel.bind("logout-event", logoutEventCallback);

      return () => {
        channel.unbind("my-event", childEventCallback);
        channel.unbind("logout-event", logoutEventCallback);
        pusher.unsubscribe(subscriber);
      };
    } else {
      console.error("No valid team name found for subscription");
    }
  }, [pusher, authData, redirect]);


  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Flex id="layout" flexDirection="column" minHeight="100vh">
      {PermissionsList && (
              <Flex
              id="header"
              flexDirection="column"
              position="sticky"
              top={0}
              zIndex={999}
              bg="white"
            >
              <Box as="header">
                <Header />
              </Box>
              <Grid
                as="nav"
                templateColumns="repeat(12, 1fr)"
                gap={4}
                className="shadow-[inset_0_8px_10px_-5px_#05369f4e]"
              >
                <GridItem colSpan={11}>
                  <SubHeader
                    routes={PermissionsList?filterRoutesByPermissions(mainRoutes, PermissionsList):getRoutes(mainRoutes)}
                  />
                </GridItem>
                {PermissionsList && checkResourcePermission(PermissionsList,"chatbot") && checkChatbot(pathname) && (
                  <GridItem
                    as={Flex}
                    colSpan={1}
                    justifyContent={"flex-end"}
                    pr={10}
                    alignItems={"center"}
                  >
                    <Icon
                      as={RiRobot2Line}
                      boxSize={6}
                      onClick={openModal}
                      cursor={"pointer"}
                    />
                  </GridItem>
                )}
              </Grid>
            </Flex>
      )}
      <Flex
        as="main"
        flex={1}
        overflow="auto"
        bgGradient="linear(to-r, #F04E4A,#0537A0)"
      >
        <Container
          id="main-container"
          maxW="3000px"
          py={8}
          display="flex"
          flexDirection="column"
          flex={1}
        >
          <Routes>
            {PermissionsList?getRoutes(filterRoutesByPermissions(mainRoutes, PermissionsList)):getRoutes((mainRoutes))}
            <Route path="/" element={<Navigate to="/auth/login" replace />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Container>
      </Flex>
      <Box as="footer">
        <FooterCustom />
      </Box>
      <Transition appear show={isOpen}>
        <Box position="fixed" top={10} right={0} zIndex={999}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Chatbot onClose={closeModal} />
          </Transition.Child>
        </Box>
      </Transition>
    </Flex>
  );
}
