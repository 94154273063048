import { ColumnDef } from "@tanstack/react-table";
import GenericDataTable from "../../../../components/table/ListingTable";
import { useNavigate } from "react-router-dom";
import axiosClient from "config/apiClient";
import { useEffect, useState } from "react";
import Loader from "components/loader/loader";
import { formatDate } from "helper/dateFormatter";

interface DataType {
  id: string;
  name: string;
  createdDate: string;
  start_date: string;
  end_date: string;
  // ... other fields
}

const columns: ColumnDef<DataType>[] = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Start Date",
    accessorKey: "start_date",
  },
  {
    header: "End Date",
    accessorKey: "end_date",
  },
  {
    header: "Created Date",
    accessorKey: "created_at",
  },
];

export const ClientListings = () => {
  const [tableDate, setTableData] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(false);

  const navigate = useNavigate();

  const getClientListing = async () => {
    try {
      const [clientListingData] = await Promise.all([
        (await axiosClient.get("/tenant/get/tenants/")).data,
      ]);
      clientListingData.forEach((element) => {
        element["end_date"] = formatDate(element?.end_date);
        element["start_date"] = formatDate(element?.start_date);
        element["created_at"] = formatDate(element?.created_at);
      });
      setTableData(clientListingData);
    } catch (error) {
      console.error("Error fetching configuration data:", error);
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    setIsInitialLoading(true);
    getClientListing();
  }, []);

  const handleCreateItem = () => {
    navigate("/client/create");
  };

  const handleViewItem = (item: DataType) => {
    navigate(`/client/view/${item.id}`);
  };

  const handleEditItem = (item: DataType) => {
    navigate(`/client/edit/${item.id}`);
  };

  return (
    <>
      {isInitialLoading ? (
        <Loader />
      ) : (
        <GenericDataTable
          data={tableDate}
          columns={columns}
          onCreateItem={handleCreateItem}
          onViewItem={handleViewItem}
          onEditItem={handleEditItem}
          createButtonText="Create Client"
          isViewable={false}
          similarWidth={false}
          actionsPresent={true}
          showTopBar={true}
        />
      )}
    </>
  );
};
