import { Image, Box, Flex } from "@chakra-ui/react";
import aboutimage from "../../assets/about/bar_frame1.jpg";
import AboutBox from "./components";
import "./chevron.css";
import { useAuth } from "../../config/AuthDataProvider";
import { useEffect} from "react";
import axiosClient from "config/apiClient";
import { useGameStore } from "../../store/store";
import { useNavigate } from "react-router-dom";

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* @ts-ignore */

const About = () => {
  const authData = useAuth();
  const navigate = useNavigate();
  let { setTimeData } = useGameStore();


  const fetchTime = async () => {
    try {
      const time = await axiosClient.get("game3/gettime");
      const last_value = time.data[time.data.length - 1];
      time.data.push(last_value);
      setTimeData(time.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   if (!authData) {
  //     navigate(`/auth/login`);
  //   } else {
  //     if (authData.isPlayer) {
  //       fetchTime();
  //     }
  //   }
  // }, []);

  return (
    <Flex
      mt={28}
      alignItems="center"
      justifyContent="space-between"
      flexDirection={{ base: "column", lg: "row" }}
    >
        <AboutBox />
      <Box
        width={{ base: "100%", lg: "50%" }}
        borderWidth={1}
        borderStyle="solid"
        borderColor="custom.borderColor"
      >
        <Image src={aboutimage} alt="About Image" objectFit="cover" />
      </Box>
    </Flex>
  );
};
export default About;
