import axios from "axios";
import { getEnvironment } from "./environment";
import { toast } from "react-toastify";
import { useAuth } from "./AuthDataProvider";

const env = getEnvironment();

const axiosClient = axios.create({
  baseURL: `${env.endpoint}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use(
  function (config) {
    const authData = useAuth();
    if (authData && authData.access) {
      config.headers.Authorization = `Bearer ${authData.access}`;
      config.headers.set("tenant-id", authData.tenant_id);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const authData = useAuth();

    let res = error.response;
    if (res.status === 401) {
      const previousRequest = error.config;
      axios
        .post(`${env.endpoint}/tenant/login/refresh/`, {
          refresh: authData.refresh,
        })
        .then((resp) => {
          let gameStoreData = localStorage.getItem("game-store");
          let gameStoreObject = JSON.parse(gameStoreData);
          let { refresh, access } = resp?.data;
          let updatedAuth = { ...authData, refresh, access };
          gameStoreObject.state.authData = updatedAuth;
          localStorage.setItem("game-store", JSON.stringify(gameStoreObject));
          axios.defaults.headers.common["Authorization"] = `Bearer ${access}`;
          return axiosClient(previousRequest);
        })
        .catch((err) => {
          console.log(err);
          let error_response = err?.response?.data;
          if (error_response?.code === "token_not_valid") {
            alert("Timeout! Please login again!");

            window.location.href = `/auth/login`;
          }
        });
    }
    if (
      [400].includes(res.status) &&
      !res?.config?.url.includes("/workshop/create/team") &&
      res?.config?.url.includes("/workshop")
    ) {
      // console.log(res);
      const regex = /=(.*?)&/;
      let acronym = res?.config?.url.match(regex)[1];
      toast.error("Workshop has Ended", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        onClose: () => redirectToWorkshopLogin(acronym),
      });
    }
    if ([403, 409].includes(res.status)) {
      // 409 - Exception Handler/Conflict
      toast.error("Unauthorized! Please login!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        onClose: redirectToLogin,
      });
    }
    console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);

function redirectToLogin() {
  window.location.href = `/auth/login`;
}

function redirectToWorkshopLogin(acronym: string) {
  window.location.href = `/play/${acronym}`;
}

export default axiosClient;
