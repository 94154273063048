import { parse , format, formatISO } from 'date-fns';

export const formatDatetime = (dateString) => {
    return format(new Date(dateString), 'PPpp');
};

export const formatDate = (dateString) => {
    return format(new Date(dateString), 'PP');
};

export const convertUTC = (dateString) => {
    const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
    const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss");
    return formattedDate;
};

export const convertUTCMin = (dateString) => {
    const parsedDate = parse(dateString, "yyyy-MM-dd HH:mm:ss", new Date());
    console.log(parsedDate)
    const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss");
    return formattedDate;
};

export const convertUTCSec = (dateString) => {
    const parsedDate = parse(dateString, "yyyy-MM-dd'T'HH:mm", new Date());
    return format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss");
  };