import React, { useEffect, useState, useCallback } from "react";
import { Box, useDisclosure } from "@chakra-ui/react";
import TabbedContentComponent from "components/tabs";
import axiosClient from "config/apiClient";
import { useParams } from "react-router-dom";
import Loader from "components/loader/loader";
import { sortByModifiedDate } from "helper/sortList";

const initialTabContents = {
  configuration: [],
  patron: [],
  priceElasticity: [],
  users: [],
};

export default function ViewClient() {
  const [isLoading, setIsLoading] = useState(true);
  const [userDetail, setUserDetails] = useState(null);
  const [tabContents, setTabContents] = useState(initialTabContents);
  const { id } = useParams();
  const [selectedPDFBlob, setSelectedPDFBlob] = useState<Blob | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchData = useCallback(async (url) => {
    try {
      const response = await axiosClient.get(url);
      return response.data;
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
      return null;
    }
  }, []);

  const getConfigurationListing = useCallback(
    async (patron_id, price_id) => {
      try {
        const [patronResponse, elasticityResponse] = await Promise.all([
          fetchData(`flow/metrics/get/patrons/?id=${patron_id}`),
          fetchData(`flow/metrics/get/elasticity/?id=${price_id}`),
        ]);

        const patronData = patronResponse.map((item) => ({
          ...item,
          filetype: "Time to Patron",
          game: "Game 3",
        }));

        const elasticityData = elasticityResponse.map((item) => ({
          ...item,
          filetype: "Price Elasticity",
          game: "Game 3",
        }));

        return sortByModifiedDate([...patronData, ...elasticityData]);
      } catch (error) {
        console.error("Error fetching configuration data:", error);
        return [];
      }
    },
    [fetchData]
  );

  useEffect(() => {
    const fetchUserDetails = async () => {
      const details = await fetchData(`tenant/get/tenants/?id=${id}`);
      if (!details) return;

      setUserDetails({
        Name: details.name,
        Created_Date: details.created_at,
        Start_Date: details.start_date,
        End_Date: details.end_date,
      });

      const { patron_id, price_elasticity_id } = details.configs || {};

      const [patron, priceElasticity, configuration] = await Promise.all([
        patron_id
          ? fetchData(`/flow/metrics/get/specific/patron/?id=${patron_id}`)
          : null,
        price_elasticity_id
          ? fetchData(
              `/flow/metrics/get/specific/elasticity/?id=${price_elasticity_id}`
            )
          : null,
        patron_id && price_elasticity_id
          ? getConfigurationListing(patron_id, price_elasticity_id)
          : null,
      ]);

      setTabContents({
        configuration: configuration || [],
        patron: patron?.patron || [],
        priceElasticity: priceElasticity?.elasticity || [],
        users: [], // Assuming this is not fetched in the original code
      });

      setIsLoading(false);
    };

    fetchUserDetails();
  }, [id, fetchData, getConfigurationListing]);

  const tabConfigurations = [
    {
      name: "Profile",
      type: "component",
      columns: [
        { header: "Name", accessorKey: "name" },
        { header: "Game", accessorKey: "game" },
        { header: "File Type", accessorKey: "filetype" },
        { header: "File Name", accessorKey: "uploaded_file_name" },
      ],
      data: tabContents.configuration,
      component: "ClientProfile",
    },
    {
      name: "Price Elasticity",
      type: "table",
      columns: [
        { header: "Price", accessorKey: "Price" },
        { header: "Demand", accessorKey: "Demands" },
        { header: "Time", accessorKey: "Time" },
        { header: "Period", accessorKey: "Period" },
      ],
      data: tabContents.priceElasticity,
    },
    {
      name: "Time To Patrons",
      type: "table",
      columns: [
        { header: "Time", accessorKey: "time" },
        { header: "Patrons", accessorKey: "patrons" },
        { header: "Period", accessorKey: "period" },
      ],
      data: tabContents.patron,
    },
    {
      name: "User",
      type: "table",
      columns: [
        { header: "Username", accessorKey: "username" },
        { header: "Email", accessorKey: "email" },
      ],
      data: tabContents.users,
    },
  ];

  if (isLoading) return <Loader />;

  return (
    <Box
      id="addclient-form"
      overflowX="auto"
      borderRadius="20px"
      display="flex"
      flexDirection="column"
      flex={1}
      fontFamily="Poppins"
      mx={{ base: "0vw", md: "18vw" }}
    >
      <TabbedContentComponent
        tabConfigurations={tabConfigurations}
        userDetails={userDetail}
        link="/client/edit/"
        header="Configuration"
        onClose={onClose}
        onOpen={onOpen}
        selectedPDFBlob={selectedPDFBlob}
        setSelectedPDFBlob={setSelectedPDFBlob}
        isOpen={isOpen}
      />
    </Box>
  );
}
