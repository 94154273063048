import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  VStack,
  Text,
  Input,
  Button,
  InputGroup,
  Grid,
  GridItem,
  Image,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import Logo from "../../../assets/game1/Logo.png";
import { useNavigate, useParams } from "react-router";
import { useCommonToast } from "components/toast/toast";
import axiosClient from "config/apiClient";
import { useGameStore } from "store/store";

const LoginComponent = () => {
  const [teamName, setTeamName] = useState("");
  const [gameCode, setGameCode] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const { acronym } = useParams();
  const showToast = useCommonToast();
  const {
    setWorkshop,
    Game2reset,
    Game3reset,
    Game1reset,
    setPermissionsList,
    setauthData,
  } = useGameStore();
  const navigate = useNavigate();

  const handleTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamName(e.target.value);
    setErrors((prev) => ({ ...prev, teamName: "" }));
  };

  const handleGameCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGameCode(e.target.value);
    setErrors((prev) => ({ ...prev, gameCode: "" }));
  };

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    if (!teamName.trim()) {
      newErrors.teamName = "Team name is required";
    }
    if (!gameCode.trim()) {
      newErrors.gameCode = "Game code is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  function clearData() {
    Game1reset();
    Game2reset();
    Game3reset();
    setWorkshop(null);
    setPermissionsList(null);
    setauthData(null);
  }

  const resetform = () => {
    setTeamName("");
    setGameCode("");
  };

  const workshopEntry = async () => {
    const resp = (
      await axiosClient.post(`/workshop/create/team/?acronym=${gameCode}`, {
        team_name: teamName,
      })
    ).data;
    return resp;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        // Simulating an API call
        const response = await workshopEntry();
        response["acronym"] = gameCode;
        // Perform login logic here
        setWorkshop(response);
        showToast("Success", "Welcome to the Workshop", "success");
        resetform();
        navigate("/play/about");
      } catch (error: any) {
        showToast(
          "Error",
          error.response?.data?.detail || error.response?.data?.error,
          "error"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      showToast("Error", "Please fill in all required fields.", "error");
    }
  };

  useEffect(() => {
    clearData();
    if (acronym) {
      setGameCode(acronym);
    }
  }, []);

  return (
    <Box
      id="addclient-form"
      overflowX="auto"
      borderRadius={"20px"}
      display="flex"
      flexDirection="column"
      flex={1}
      fontFamily="Poppins"
      mx={{ base: "0vw", md: "10vw" }}
      my={{ base: "0vh", md: "5vh" }}
    >
      <Flex direction={"column"} flex={1}>
        <Flex
          bg={"Containerbg"}
          flex={1}
          py={"10px"}
          px={"20px"}
          direction={"column"}
          justifyContent="space-between"
        >
          <Box as={Flex} flex={1}>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              w={"100%"}
              flex={1}
            >
              <Grid
                as={Flex}
                templateColumns="repeat(2, 1fr)"
                gap={6}
                w={"100%"}
                flex={1}
              >
                <GridItem
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                  bg={"white"}
                  borderRadius={"8px"}
                  p={8}
                  mx={2}
                >
                  <Box bg={"white"} display="flex" flex={1}>
                    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                      <VStack spacing={4} align="stretch" w="100%">
                        <Text
                          textAlign="center"
                          color={"#0E389D"}
                          fontWeight={700}
                          fontSize={18}
                        >
                          Welcome Back!
                        </Text>
                        <Text textAlign="center">Login to continue</Text>
                        <FormControl isInvalid={!!errors.teamName}>
                          <FormLabel>Team Name</FormLabel>
                          <Input
                            type="text"
                            value={teamName}
                            onChange={handleTeamChange}
                            placeholder="Enter your team name"
                          />
                          <FormErrorMessage>{errors.teamName}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.gameCode}>
                          <FormLabel>Game Code</FormLabel>
                          <InputGroup>
                            <Input
                              type="text"
                              value={gameCode}
                              onChange={handleGameCodeChange}
                              placeholder="Enter your game code"
                            />
                          </InputGroup>
                          <FormErrorMessage>{errors.gameCode}</FormErrorMessage>
                        </FormControl>
                        <Button
                          type="submit"
                          bgGradient="linear(to-b, #5C86DC,#2647B0)"
                          color={"white"}
                          isLoading={isLoading}
                        >
                          Login
                        </Button>
                      </VStack>
                    </form>
                  </Box>
                </GridItem>
                <GridItem
                  p={10}
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Image src={Logo} alt="Login visual" objectFit="cover" />
                  </Box>
                </GridItem>
              </Grid>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default LoginComponent;
