import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
} from "@chakra-ui/react";
import Select, { components } from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosClient from "config/apiClient";
import { useCommonToast } from "components/toast/toast";
import Loader from "components/loader/loader";
import { format } from 'date-fns';
import { convertUTC, formatDate } from "helper/dateFormatter";

interface FormErrors {
  clientName?: string;
  startDate?: string;
  endDate?: string;
  [key: string]: string | undefined;
}

const CreateClientForm = () => {
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [selectedGames, setSelectedGames] = useState<Record<string, any>>({});
  const [timeData, setTimeData] = useState<Record<string, any>>({
    startDate: "",
    endDate: "",
  });
  const [intialdata, setIntialData] = useState<Record<string, any>>({});
  const [selectOptions, setselectOptions] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<FormErrors>({});
  const navigate = useNavigate();
  const showToast = useCommonToast();
  const queryParam = useParams();
  const location = useLocation();
  const isEditable = location.pathname.includes("edit")
  let currentDate = new Date().toJSON().slice(0, 10);
  console.log(currentDate);
  const formFields = [
    {
      label: "Game 3 - Price Elasticity",
      id: "price_elasticity_id",
      helperText: "Select the game configuration for the client",
      option: selectOptions["priceElasticity"],
    },
    {
      label: "Game 3 - Time to Patron",
      id: "patron_id",
      helperText: "Select the game configuration for the client",
      option: selectOptions["timePatron"],
    },
  ];

  const CustomMenu = (props) => {
    return (
      <components.Menu {...props}>
        {props.children}
        <Button
          onClick={() =>
            navigate("/configuration/create", {
              state: { redirect: true },
            })
          }
          width={"100%"}
          bg={"transparent"}
          textColor={"#0B389E"}
          padding={2}
          cursor={"pointer"}
          borderRadius={0}
          borderTop={"1px"}
          borderColor={"gray.300"}
        >
          Create New Game Data
        </Button>
      </components.Menu>
    );
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF4D",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "30vh",
      overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#2647B0" : state.isSelected ? "#2647B080" : provided.backgroundColor,
      color: state.isFocused ? "white" : provided.color,
      '&:hover': {
        backgroundColor: "#2647B0",
        color: "white",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.selectProps.isDisabled ? "#718096" : provided.color,
    }),
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};
    if (!clientName.trim()) {
      newErrors.clientName = "Client name is required";
    }
    formFields.forEach((field) => {
      if (!selectedGames[field.id]) {
        newErrors[field.id] = "Game selection is required";
      }
    });
    Object.keys(timeData).forEach((key) => {
      if (!timeData[key]) {
        newErrors[key] = "Date is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (): Promise<void> => {
    if (validateForm()) {
      if (timeData.startDate && timeData.endDate) {
        const startDate = new Date(timeData.startDate as string);
        const endDate = new Date(timeData.endDate as string);
        if (endDate <= startDate) {
          showToast("Error", "End date and time must be after the start date and time", "error");
          return;
        }
      }
      setIsLoading(true);
      try {
        const clientData = new FormData();
        const configData = new FormData();
        clientData.append("name", clientName);
        clientData.append("game", selectedGames["price_elasticity_id"].value);
        clientData.append("type", selectedGames["patron_id"].value);
        clientData.append("start_date", convertUTC(timeData["startDate"]));
        clientData.append("end_date", convertUTC(timeData["endDate"]));
        configData.append(
          "price_elasticity_id",
          selectedGames["price_elasticity_id"].value
        );
        configData.append("patron_id", selectedGames["patron_id"].value);
        configData.append("game_type", "GAME3");
        const response = await createClient(clientData, configData);
        showToast("Success", response.message, "success");
        resetForm();
        navigate("/client/list")
      } catch (error: any) {
        showToast("Error", error.response?.data?.error, "error");
      } finally {
        setIsLoading(false);
      }
    } else {
      showToast("Error", "Please fill in all required fields.", "error");
    }
  };

  const resetForm = () => {
    if(!isEditable){
      setClientName("");
      setTimeData({
        startDate: "",
        endDate: "",
      });
    }
    setSelectedGames({});
    setTimeData(prev => ({
      ...prev,
      endDate: "",
    }));
    setTimeData(prev => ({
      ...prev,
      endDate: "",
    }));
    setErrors({});
  };

  const handleGameChange = (selectedOption, fieldId) => {
    setSelectedGames((prev) => ({
      ...prev,
      [fieldId]: selectedOption,
    }));
  };

  function createSelectOptions(data1, data2) {
    const selectOptions = {
      priceElasticity: [],
      timePatron: [],
    };
    console.log(data1);
    data1.forEach((item) => {
      selectOptions.priceElasticity.push({
        label: item.name,
        value: item.id,
      });
    });

    data2.forEach((item) => {
      selectOptions.timePatron.push({
        label: item.name,
        value: item.id,
      });
    });

    return selectOptions;
  }

  const getConfigurationOptions = async () => {
    try {
      const [patronResponse, elasticityResponse] = await Promise.all([
        axiosClient.get("flow/metrics/get/patrons/"),
        axiosClient.get("flow/metrics/get/elasticity/"),
      ]);
      if(isEditable)
      {
        const [userData] = await Promise.all([
          (await axiosClient.get(`tenant/get/tenants/?id=${queryParam.id}`)).data,
        ]);
        setIntialData(userData)
        setClientName(userData.name)
        setTimeData((prev) => ({
          ...prev,
          startDate: format(userData.start_date,'yyyy-MM-dd'),
          endDate: format(userData.end_date,'yyyy-MM-dd')
        }))
        setSelectedGames((prev) =>({
          ...prev,
          price_elasticity_id: {
            label: userData.configs.price_elasticity__name,
            value: userData.configs.price_elasticity_id,
          },
          patron_id: {
            label: userData.configs.patron__name,
            value: userData.configs.patron_id,
          },
        }))
      }
      const selectedOptions = createSelectOptions(
        elasticityResponse.data,
        patronResponse.data
      );
      setselectOptions(selectedOptions);
    } catch (error) {
      console.error("Error fetching configuration data:", error);
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    if(!isEditable){
      resetForm();
    }
  }, [isEditable]);

  useEffect(() => {
    setIsInitialLoading(true);
    getConfigurationOptions();
  }, []);

  const createClient = async (clientData: FormData, configData: FormData) => {
    let resp;
    try {
      resp = await axiosClient[isEditable ? 'put' : 'post'](`tenant/manage/${isEditable?`${queryParam.id}/`:""}`, clientData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      configData.append("tenant_id", resp.data.id);
      console.log(resp.data)
      await axiosClient[isEditable ? 'put' : 'post'](`flow/metrics/configuration/${isEditable?`${intialdata.configs.id}/`:""}`, configData, {
        headers: {
          "Content-Type": "form-data",
        },
      });
    } catch(error) {
      throw error
    }
    return resp.data;
  };

  return (
    <>
      {isInitialLoading ? (
        <Loader />
      ) : (
        <Box
          id="addclient-form"
          overflowX="auto"
          borderRadius={"20px"}
          display="flex"
          flexDirection="column"
          flex={1}
          fontFamily="Poppins"
          mx={{ base: "0vw", md: "18vw" }}
        >
          <Flex direction={"column"} flex={1}>
            <Flex
              bg={"TableHeaderbg"}
              justify="space-between"
              align={"center"}
              py={"10px"}
              px={"20px"}
              textColor="#0B389E"
              fontWeight={600}
              fontSize={"18px"}
            >
              {isEditable ? "Edit " : "Create "} Client
            </Flex>
            <Flex
              bg={"Containerbg"}
              flex={1}
              py={"10px"}
              px={"20px"}
              direction={"column"}
              justifyContent="space-between"
            >
              <Flex id="form-box" gap={4} direction={"column"}>
                <FormControl
                  isRequired
                  isInvalid={errors.clientName ? true : false}
                  onFocus={() => {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      clientName: "",
                    }));
                  }}
                >
                  <FormLabel>Client Name</FormLabel>
                  <Input
                    placeholder="Enter the client name"
                    bg={"InputBoxbg"}
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                    _placeholder={{ color: "PlaceHolderColor" }}
                    isDisabled={isEditable}
                    _disabled={{color:"gray.500"}}
                  />
                  <FormHelperText
                    color={errors.clientName ? "red.500" : "inherit"}
                  >
                    {errors.clientName}
                    {/* "Enter the client name you want to create" */}
                  </FormHelperText>
                </FormControl>
                {formFields.map((field) => (
                  <FormControl
                    key={field.id}
                    isRequired
                    isInvalid={errors[field.id] ? true : false}
                    onFocus={() => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        [field.id]: "",
                      }));
                    }}
                  >
                    <FormLabel>{field.label}</FormLabel>
                    <Select
                      id={`select-${field.id}`}
                      classNamePrefix="select"
                      name={field.id}
                      isLoading={isLoading}
                      isClearable={true}
                      isSearchable={true}
                      options={field.option}
                      // components={{ Menu: CustomMenu }}
                      styles={customStyles}
                      value={selectedGames[field.id] || null}
                      onChange={(selectedOption) =>
                        handleGameChange(selectedOption, field.id)
                      }
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                    />
                    <FormHelperText
                      color={errors[field.id] ? "red.500" : "inherit"}
                    >
                      {errors[field.id] || field.helperText}
                    </FormHelperText>
                  </FormControl>
                ))}
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                  }}
                  gap={2}
                >
                  <FormControl
                    isRequired
                    isInvalid={errors.startDate ? true : false}
                    onFocus={() => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        startDate: "",
                      }));
                    }}
                  >
                    <FormLabel>Start Date</FormLabel>
                    <Input
                      placeholder="Select Start Date"
                      size="md"
                      type="date"
                      bg={"InputBoxbg"}
                      value={timeData?.startDate}
                      min={currentDate}
                      max={timeData?.endDate}
                      isDisabled={isEditable}
                      _disabled={{color:"gray.500"}}
                      onChange={(e) =>
                        setTimeData((prev) => ({
                          ...prev,
                          startDate: e.target.value,
                        })
                        )
                      }
                    />
                    <FormHelperText
                      color={errors.startDate ? "red.500" : "inherit"}
                    >
                      {errors.startDate ||
                        "Enter the start date of client contract"}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    isRequired
                    isInvalid={errors.endDate ? true : false}
                    onFocus={() => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        endDate: "",
                      }));
                    }}
                  >
                    <FormLabel>End Date</FormLabel>
                    <Input
                      placeholder="Select End Date"
                      size="md"
                      type="date"
                      bg={"InputBoxbg"}
                      onChange={(e) =>
                        setTimeData((prev) => ({
                          ...prev,
                          endDate: e.target.value,
                        }))
                      }
                      value={timeData?.endDate}
                      min={timeData?.startDate}
                      isDisabled={timeData?.startDate === ""}
                    />
                    <FormHelperText
                      color={errors.endDate ? "red.500" : "inherit"}
                    >
                      {errors.endDate ||
                        "Enter the end date of client contract"}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Flex>
              <Flex
                id="button-box"
                justifyContent={"flex-end"}
                alignItems={"flex-end"}
                gap={3}
                mt={4}
              >
                <Button
                  borderRadius={"8px"}
                  bg={"white"}
                  textColor={"#0B389E"}
                  border={"2px"}
                  borderColor={"#5C85DC"}
                  py={2}
                  px={4}
                  onClick={resetForm}
                >
                  Cancel
                </Button>
                <Button
                  borderRadius={"8px"}
                  bgGradient="linear(to-b, #5C86DC,#2647B0)"
                  textColor={"white"}
                  onClick={handleSubmit}
                  isLoading={isLoading}
                  py={2}
                  px={4}
                >
                  Confirm
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default CreateClientForm;
